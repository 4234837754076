import { SIGN_OUT, SET_ROAD_TRIP_CARRIER_INFO, GET_ROAD_TRIP_CARRIER_COVERAGES } from "constants/ActionTypes";

const INIT_STATE = {
    roadTripCarrierCoverages: {
        data: [],
        count: 0
    },
    originId: null,
    destinationId: null,
    carrierId: null,
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case SIGN_OUT: {
            return INIT_STATE;
        }

        case SET_ROAD_TRIP_CARRIER_INFO: {
            return {
                ...state,
                originId: action.payload?.originId,
                destinationId: action.payload?.destinationId,
                carrierId: action.payload?.carrierId,
            };
        }

        case GET_ROAD_TRIP_CARRIER_COVERAGES: {
            return {
                ...state,
                roadTripCarrierCoverages: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}