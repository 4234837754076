import React, { useState, useEffect } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from 'components/Navigation';
import { useSelector } from 'react-redux';

// zmdi icons : http://nd2.godesign.ch/examples/extended/icons.html

const SideBarContent = () => {
  const [navigationMenus, setNavigationMenus] = useState([]);
  const authUser = useSelector(({ auth }) => auth.authUser);
  const permissionAuthorities = authUser?.permissionAuthorities ?? [];
  const canManageWalletApplications = authUser?.paymentArrangementCanManageWalletApplications;

  const isRouteAvailable = permissionAuthority =>
    permissionAuthorities.includes('PERM_BO_ADMIN') ||
    permissionAuthorities.includes(permissionAuthority);

  const fillNavigationMenus = () => {
    const newNavigationMenus = [
      {
        name: 'dashboards',
        type: 'section',
        children: []
      },
      {
        name: 'cadastros',
        type: 'section',
        children: []
      },
      {
        name: 'ponto de venda',
        type: 'section',
        children: []
      },
      {
        name: 'carteiras',
        type: 'section',
        children: []
      },
      {
        name: 'movimentações financeiras',
        type: 'section',
        children: []
      },
      {
        name: 'relatórios',
        type: 'section',
        children: []
      },
      {
        name: 'configurações',
        type: 'section',
        children: []
      },
      {
        name: 'viagens',
        type: 'section',
        children: []
      },
    ];

    if (authUser.isCompanyOrEmployerUser) {
      if (isRouteAvailable('PERM_BO_COMPANY_DASHBOARD')) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'pages.company.dashboard',
          link: '/company-dashboard',
          icon: 'chart',
        });
      }

      if (isRouteAvailable('PERM_BO_COMPANY_EMPLOYEES')) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Grupos de Colaboradores',
          link: '/company-employees-groups',
          icon: 'labels',
        });
      }

      if (isRouteAvailable('PERM_BO_COMPANY_EMPLOYEES')) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'pages.company.employees',
          link: '/company-employees',
          icon: 'accounts',
        });
      }

      if (isRouteAvailable('PERM_BO_COMPANY_EMPLOYEES')) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'Contas',
          link: '/evom-account',
          icon: 'accounts-list',
        });
      }

      if (isRouteAvailable('PERM_BO_COMPANY_INVOICES')) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'pages.company.invoices',
          link: '/company-invoices',
          icon: 'local-grocery-store',
        });
      }

      if (isRouteAvailable('PERM_BO_COMPANY_STATEMENT')) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Extrato do Empregador',
          link: '/company-statement',
          icon: 'receipt',
        });
      }

      if (isRouteAvailable('PERM_BO_COMPANY_ROAD_TRIP_CARRIER_COVERAGE')) {
        newNavigationMenus[7].children.push({
          type: 'item',
          name: 'Busca de viagens',
          link: '/road-trip-carrier-coverage',
          icon: 'navigation',
        });
      }
    }

    if (authUser.isPaymentArrangementUser) {
      if (isRouteAvailable('PERM_BO_PMTARG_REAL_TIME_DASHBOARD')) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Tempo Real',
          link: '/payment-arrangement-real-time-dashboard',
          icon: 'chart-donut',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_TRANSACTION_DASHBOARD')) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Transações',
          link: '/payment-arrangement-transactions-dashboard',
          icon: 'chart',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_ENROLLMENTS_DASHBOARD')) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Cadastros',
          link: '/payment-arrangement-enrollments-dashboard',
          icon: 'collection-text',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_CREDIT_AND_CONSUMPTION_DASHBOARD')) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Crédito e consumo de carteiras',
          link: '/credit-and-consumption-dashboard',
          icon: 'receipt',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_FINANCIAL_TRANSACTIONS_DASHBOARD')) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'Movimentação financeira',
          link: '/financial-transactions',
          icon: 'swap',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_COMPANIES')) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'pages.companies',
          link: '/companies',
          icon: 'city',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_USERS')) {
        newNavigationMenus[1].children.push({
          type: 'item',
          name: 'pages.users',
          link: '/users',
          icon: 'account',
        });
      }

      if (isRouteAvailable('PERM_BO_POS_AGENCY')) {
        newNavigationMenus[2].children.push({
          type: 'item',
          name: 'Agências',
          link: '/pos-agency',
          icon: 'store',
        });
      }

      if (isRouteAvailable('PERM_BO_POS_AGENCY_USERS')) {
        newNavigationMenus[2].children.push({
          type: 'item',
          name: 'Usuários Agências',
          link: '/agency-users',
          icon: 'accounts-alt',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_POS_USERS')) {
        newNavigationMenus[2].children.push({
          type: 'item',
          name: 'Operadores PDV',
          link: '/pos-users',
          icon: 'case',
        });
      }

      if (isRouteAvailable('PERM_BO_POS_TRANSACTIONS')) {
        newNavigationMenus[2].children.push({
          type: 'item',
          name: 'Transações',
          link: '/pos-transactions',
          icon: 'local-atm',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_EVOM_ACCOUNT')) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Buscar',
          link: '/payment-arrangement/evom-account',
          icon: 'search',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_EVOM_ACCOUNT')) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Contas',
          link: '/evom-account-wallets',
          icon: 'accounts',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_WALLET_REQUESTS') && canManageWalletApplications) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Solicitações de carteiras',
          link: '/wallet-requests',
          icon: 'assignment-account',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_WALLET_REQUESTS') && canManageWalletApplications) {
        newNavigationMenus[3].children.push({
          type: 'item',
          name: 'Histórico de solicitações',
          link: '/wallet-requests-history',
          icon: 'file-text',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_PAYMENT_ARRANGEMENT_INVOICES')) {
        newNavigationMenus[4].children.push({
          type: 'item',
          name: 'pages.payment.arrangement.invoices',
          link: '/payment-arrangement-invoices',
          icon: 'collection-text',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_CREDITS')) {
        newNavigationMenus[4].children.push({
          type: 'item',
          name: 'pages.payment.arrangement.credits',
          link: '/credits',
          icon: 'local-atm',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_EXPENSES')) {
        newNavigationMenus[4].children.push({
          type: 'item',
          name: 'pages.payment.arrangement.expenses',
          link: '/expenses',
          icon: 'money',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_CREDIT_REPORT')) {
        newNavigationMenus[5].children.push({
          type: 'item',
          name: 'Crédito Diário em carteiras',
          link: '/credit-report',
          icon: 'local-atm',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_EXPENSES_REPORT')) {
        newNavigationMenus[5].children.push({
          type: 'item',
          name: 'Consumo Diário',
          link: '/expenses-report',
          icon: 'money',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_EXPORTS')) {
        newNavigationMenus[5].children.push({
          type: 'item',
          name: 'Exportações',
          link: '/exportacoes',
          icon: 'download',
        });
      }

      if (isRouteAvailable('PERM_BO_POS_REPORTS')) {
        newNavigationMenus[5].children.push({
          type: 'item',
          name: 'Vendas diárias - PDV',
          link: '/pos-sales-by-day-report',
          icon: 'assignment-o',
        });
      }

      if (isRouteAvailable('PERM_BO_POS_REPORTS')) {
        newNavigationMenus[5].children.push({
          type: 'item',
          name: 'Vendas por Operador - PDV',
          link: '/pos-sales-by-operator-report',
          icon: 'assignment-o',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_ACCOUNTING_REPORT')) {
        newNavigationMenus[5].children.push({
          type: 'item',
          name: 'Relatório contábil',
          link: '/accounting-report',
          icon: 'money',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_REFERRAL_CODE_REPORT')) {
        newNavigationMenus[5].children.push({
          type: 'item',
          name: 'Relatório de indicações',
          link: '/referral-codes-report',
          icon: 'accounts-list',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_REPORT_TEMPLATE')) {
        newNavigationMenus[5].children.push({
          type: 'item',
          name: 'Outros',
          link: '/outros-relatorios',
          icon: 'view-list-alt',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_WALLET_PARAMETERIZATION') && canManageWalletApplications) {
        newNavigationMenus[6].children.push({
          type: 'item',
          name: 'Parametrização de carteiras',
          link: '/wallet-parameterization',
          icon: 'settings',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_COMMISSION_MODEL') && canManageWalletApplications) {
        newNavigationMenus[6].children.push({
          type: 'item',
          name: 'Modelos de comissão',
          link: '/commission-models',
          icon: 'account',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_REFERRAL_CODE') && canManageWalletApplications) {
        newNavigationMenus[6].children.push({
          type: 'item',
          name: 'Códigos de indicação',
          link: '/referral-codes',
          icon: 'account',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_PROMOTIONS_CASH_IN')) {
        newNavigationMenus[6].children.push({
          type: 'item',
          name: 'Promoções de recarga',
          link: '/promotions-cash-in',
          icon: 'card-giftcard',
        });
      }

      if (isRouteAvailable('PERM_BO_PMTARG_PROMOTIONS_CASH_OUT')) {
        newNavigationMenus[6].children.push({
          type: 'item',
          name: 'Promoções de consumo',
          link: '/promotions-cash-out',
          icon: 'card-giftcard',
        });
      }
    }
    

    if (authUser.isPOSAgencyUser) {
      if (isRouteAvailable('PERM_BO_POS_DASHBOARD')) {
        newNavigationMenus[0].children.push({
          type: 'item',
          name: 'pages.company.dashboard',
          link: '/pos-agency-dashboard',
          icon: 'chart',
        });
      }

      if (isRouteAvailable('PERM_BO_COMPANY_INVOICES')) {
        newNavigationMenus[4].children.push({
          type: 'item',
          name: 'Faturas da Agência',
          link: '/company-invoices',
          icon: 'local-grocery-store',
        });
      }

      if (isRouteAvailable('PERM_BO_COMPANY_STATEMENT')) {
        newNavigationMenus[4].children.push({
          type: 'item',
          name: 'Extrato da Agência',
          link: '/company-statement',
          icon: 'receipt',
        });
      }
    }

    const filteredNewNavigationMenus = newNavigationMenus.filter(navigationSection => navigationSection.children.length !== 0);

    setNavigationMenus(
      filteredNewNavigationMenus.length !== 0 ?
        filteredNewNavigationMenus :
        [{ name: 'Nenhuma página disponível', type: 'section' }]
    );
  };

  useEffect(() => {
    if (authUser) {
      fillNavigationMenus();
    }
  }, [authUser]);

  return (
    <CustomScrollbars className="scrollbar">
      <Navigation menuItems={navigationMenus}/>
    </CustomScrollbars>
  );
};

export default SideBarContent;
