import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Common from './Common';
import Company from './CompanyReducers';
import Credit from './CreditReducers';
import Expense from './ExpenseReducers';
import WalletConfigs from './WalletParameterizationReducers';
import WalletRequests from './WalletRequestsReducers';
import EvomAccount from './EvomAccountReducers';
import PosUSer from './PosUserReducer';
import SimpleListsReducer from './SimpleListsReducers';
import CompanyEmployee from './CompanyEmployeeReducer';
import CompanyEmployeeGroups from './CompanyEmployeeGroupsReducer';
import UserReducer from './UserReducer';
import CompanyInvoiceReducer from './CompanyInvoiceReducer';
import PaymentArrangementInvoice from './PaymentArrangementInvoiceReducers';
import Companies from './CompanyReducer';
import SystemProperties from './SystemPropertiesReducer';
import EvomAccountDetails from './EvomAccountDetailsReducer';
import CompanyStatementReducer from './CompanyStatementReducer';
import PosAgency from './PosAgencyReducer';
import PosTransactions from './PosTransactionReducer';
import PosAgencyUsers from './PosAgencyUsersReducer';
import RoadTripCarrierCoverageReducer from './RoadTripCarrierCoverageReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  common: Common,
  company: Company,
  credit: Credit,
  expense: Expense,
  walletConfigs: WalletConfigs,
  walletRequests: WalletRequests,
  evomAccount: EvomAccount,
  posUser: PosUSer,
  posAgency: PosAgency,
  posAgencyUsers: PosAgencyUsers,
  posTransactions: PosTransactions,
  simpleLists: SimpleListsReducer,
  companyEmployee: CompanyEmployee,
  companyEmployeeGroups: CompanyEmployeeGroups,
  user: UserReducer,
  companyInvoice: CompanyInvoiceReducer,
  paymentArrangementInvoice: PaymentArrangementInvoice,
  companies: Companies,
  systemProperties: SystemProperties,
  evomAccountDetails: EvomAccountDetails,
  companyStatement: CompanyStatementReducer,
  roadTripCarrierCoverages: RoadTripCarrierCoverageReducer,
});
